<template>
	<div class="wrapper wrapper-8">
		<Progress @back="goToStep(5)" :black="false" :last="true" color="#000000" />
		<main class="content">
			<div class="content__wrapper">
				<h1 class="content__title content__title--black">Предпочтительный способ связи:</h1>
				<div class="qwiz-area">
					<div class="content__item">
						<label class="content__item--checkbox content__item--checkbox-black">
							<input type="radio" name="messenger" value="telegram" v-model="selectedMessenger" @change="toggleMessenger">
							<span>Телеграм</span>
						</label>
						<div v-if="selectedMessenger === 'telegram'">
							<input type="text" class="content__item--form-control content__item--form-control--black" v-model="telegram" @input="updateTelegram">
						</div>
					</div>
					<div class="content__item">
						<label class="content__item--checkbox content__item--checkbox-black">
							<input type="radio" name="messenger" value="whatsapp" v-model="selectedMessenger" @change="toggleMessenger">
							<span>WhatsApp</span>
						</label>
						<div v-if="selectedMessenger === 'whatsapp'">
							<input type="text" class="content__item--form-control content__item--form-control--black" v-model="whatsapp" @input="updateWhatsApp">
						</div>
					</div>
					<a href="javascript:void(0)" @click="next()" class="button" v-if="!loading">Продолжить</a>
					<a href="javascript:void(0)" class="button" v-else>Отправляем...</a>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
// import QuestionCheckbox from '@/components/QuestionCheckbox2'
import Progress from '@/components/Progress'
export default {
	name: 'Step_6',
	data() {
		return {
			name: 'connection',
			loading: false,
			telegram: '',
			whatsapp: '',
			selectedMessenger: '',
		}
	},
	computed: {
		values() {
			return this.$store.state.values;
		},
		step() {
			return this.$store.state.step;
		}
	},
	components: {
		Progress,
		// QuestionCheckbox,
	},
	mounted() {
		if (this.$store.state.userPhone && this.$store.state.userPhone.length > 0) {
			this.telegram = this.$store.state.userPhone;
			this.whatsapp = this.$store.state.userPhone;
		}
	},
	methods: {
		goToStep(value) {
			this.$store.dispatch('setStepBack', value);
			this.$router.push({ path: '/', query: { step: this.step } });
		},
		toggleMessenger() {
			if (this.selectedMessenger === 'telegram') {
				this.addTelegram();
				this.removeWhatsApp();
			} else if (this.selectedMessenger === 'whatsapp') {
				this.addWhatsApp();
				this.removeTelegram();
			}
		},
		updateTelegram() {
			if (this.selectedMessenger === 'telegram') {
				this.setValue({ id: 7, title: 'Telegram', answerText: this.telegram, answer: this.telegram });
			}
		},
		updateWhatsApp() {
			if (this.selectedMessenger === 'whatsapp') {
				this.setValue({ id: 8, title: 'WhatsApp', answerText: this.whatsapp, answer: this.whatsapp });
			}
		},
		addTelegram() {
			this.setValue({ id: 7, title: 'Telegram', answerText: this.telegram, answer: this.telegram });
		},
		removeTelegram() {
			this.$store.dispatch('removeValue', 7);
		},
		addWhatsApp() {
			this.setValue({ id: 8, title: 'WhatsApp', answerText: this.whatsapp, answer: this.whatsapp });
		},
		removeWhatsApp() {
			this.$store.dispatch('removeValue', 8);
		},
		setValue(stepData) {
			this.$store.dispatch('setValue', stepData);
		},
		next() {
			if ((this.selectedMessenger === 'telegram' && !this.telegram) ||
					(this.selectedMessenger === 'whatsapp' && !this.whatsapp) ||
					!this.selectedMessenger) {
				this.$toast.error('Пожалуйста, выберите предпочтительный способ связи.');
				return;
			}
			this.loading = true;
			let payload = {
				"coach_id": this.$store.state.coachId,
				"user_id": this.$store.state.userId,
				"data": this.values,
			}
			this.$store.dispatch('sendQuiz', payload).then(() => {
				this.$router.push({ path: '/', query: { step: 7 } });

				this.loading = false;
			}).catch(() => {
				this.loading = false;
			})
		}
	}
}
</script>
