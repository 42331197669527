<template>
  <div class="wrapper wrapper-8">
    <Progress @back="goToStep(6)" :black="true" :isBack="false" :displayNone="true" />
    <main class="content" :id="7">
      <div class="content__wrapper content__wrapper--center">
        <h1 class="content__text  content__text--black finish">
          <slot name="Text"><img src="@/assets/img/icon_checkbox.svg" class="success_icon" alt="">Спасибо, что ответили на вопросы анкеты. Мы направили вашу заявку
            тренеру. В ближайшее время тренер свяжется с вами.
            <br>Успехов в достижении ваших беговых целей!
          </slot>
        </h1>
      </div>
      <a href="javascript:void(0)" @click="goToApp" class="button">Завершить</a>
    </main>
  </div>
</template>

<script>
import Progress from '@/components/Progress'
export default {
  name: 'Step_7',
  computed: {
    step() {
      return this.$store.state.step;
    },
		userFullName() {
			return this.$store.state.userFullName;
		},
  },
  components: {
    Progress,
  },
  methods: {
    goToStep(value) {
      this.$store.dispatch('setStepBack', value);
      this.$router.push({ path: '/', query: { step: this.step } });
    },
    goToApp() {
      window.location.href = 'app://form-finish';
    }
  },
}
</script>

<style>
  .success_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }
</style>